var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-cash-register","title":_vm.$tc('Views.r-1')}}),_c('v-row',{class:_vm.bills.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$tc('Views.r-2')}}):(!_vm.loader)?_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('v-text-field',{staticClass:"px-3 my-3",attrs:{"readonly":_vm.loadingDT,"append-icon":"mdi-magnify","label":_vm.$tc('Views.bid-2'),"outlined":"","clearable":"","single-line":"","hide-details":"","color":_vm.getInputColor},on:{"click:append":_vm.searchReturnByID,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchReturnByID($event)},"click:clear":function($event){return _vm.searchReturnByID(true)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"customDT",attrs:{"headers":_vm.headers,"items":_vm.bills,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingDT,"footer-props":{
            itemsPerPageText: ("" + (_vm.$tc('Views.r-3-1'))),
            pageText: ("{0}-{1} " + (_vm.$tc('Newsletter.newslettersByPage')) + " {2}"),
            'items-per-page-all-text': _vm.$tc('Newsletter.allNewsletters'),
          },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","absolute":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(" "+_vm._s(_vm.$tc("Views.r-4"))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(_vm._s(_vm.$tc("Newsletter.loadingSearch")))])]},proxy:true},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.date))+" ")]}},{key:"item.client",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.email)+" ")]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.progress",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.getInvestmentTimeline(item).investment_current_percentage)+"% ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status.name == _vm.$constants.STATUS.TO_DO)?_c('v-chip',{attrs:{"small":"","label":"","color":"amber darken-4","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):(item.status.name == _vm.$constants.STATUS.VALIDATING)?_c('v-chip',{attrs:{"small":"","label":"","color":"blue-grey","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):(item.status.name == _vm.$constants.STATUS.IN_PROCESS)?_c('v-chip',{attrs:{"small":"","label":"","color":"green","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):(item.status.name == _vm.$constants.STATUS.RESERVED)?_c('v-chip',{attrs:{"small":"","label":"","color":"pink darken-3","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):(item.status.name == _vm.$constants.STATUS.FINISHED)?_c('v-chip',{attrs:{"small":"","label":"","color":"indigo","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):(item.status.name == _vm.$constants.STATUS.CANCELED)?_c('v-chip',{attrs:{"small":"","label":"","color":"red darken-3","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(item.status.name))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.status.name == _vm.$constants.STATUS.VALIDATING)?_c('div',{staticClass:"ma-3"},[_c('div',{directives:[{name:"can",rawName:"v-can:read",value:([
                  _vm.$constants.PRIVILEGES.PAYMENT,
                  _vm.$constants.PRIVILEGES.BILL ]),expression:"[\n                  $constants.PRIVILEGES.PAYMENT,\n                  $constants.PRIVILEGES.BILL,\n                ]",arg:"read"}],staticClass:"my-2"},[_c('v-icon',{on:{"click":function($event){return _vm.billDetail(item.id)}}},[_vm._v(" mdi-eye ")])],1),_c('div',{directives:[{name:"can",rawName:"v-can:update",value:([_vm.$constants.PRIVILEGES.PAYMENT]),expression:"[$constants.PRIVILEGES.PAYMENT]",arg:"update"}],staticClass:"my-2"},[_c('v-icon',{on:{"click":function($event){return _vm.paymentValidation(item.id)}}},[_vm._v(" mdi-cash-check ")])],1)]):_vm._e(),(item.status.name == _vm.$constants.STATUS.TO_DO)?_c('div',{staticClass:"ma-3"},[_c('div',{directives:[{name:"can",rawName:"v-can:read",value:([
                  _vm.$constants.PRIVILEGES.PAYMENT,
                  _vm.$constants.PRIVILEGES.BILL ]),expression:"[\n                  $constants.PRIVILEGES.PAYMENT,\n                  $constants.PRIVILEGES.BILL,\n                ]",arg:"read"}],staticClass:"my-2"},[_c('v-icon',{on:{"click":function($event){return _vm.billDetail(item.id)}}},[_vm._v(" mdi-eye ")])],1)]):_vm._e(),(
                item.status.name == _vm.$constants.STATUS.RESERVED ||
                item.status.name == _vm.$constants.STATUS.WAITING_FOR_USERS
              )?_c('div',{staticClass:"ma-3"},[_c('div',{directives:[{name:"can",rawName:"v-can:read",value:([
                  _vm.$constants.PRIVILEGES.PAYMENT,
                  _vm.$constants.PRIVILEGES.BILL ]),expression:"[\n                  $constants.PRIVILEGES.PAYMENT,\n                  $constants.PRIVILEGES.BILL,\n                ]",arg:"read"}],staticClass:"my-2"},[_c('v-icon',{on:{"click":function($event){return _vm.billDetail(item.id)}}},[_vm._v(" mdi-eye ")])],1),_c('div',{directives:[{name:"can",rawName:"v-can:delete",value:([_vm.$constants.PRIVILEGES.BILL]),expression:"[$constants.PRIVILEGES.BILL]",arg:"delete"}],staticClass:"my-2"},[(item.status.name !== _vm.$constants.STATUS.DELETED)?_c('v-icon',{directives:[{name:"can",rawName:"v-can:delete",value:([_vm.$constants.PRIVILEGES.PRODUCT]),expression:"[$constants.PRIVILEGES.PRODUCT]",arg:"delete"}],on:{"click":function($event){return _vm.deleteProductConfirm(item)}}},[_vm._v(" mdi-cancel ")]):_vm._e()],1)]):_vm._e(),(
                item.status.name == _vm.$constants.STATUS.IN_PROCESS ||
                item.status.name == _vm.$constants.STATUS.FINISHED ||
                item.status.name == _vm.$constants.STATUS.CANCELED
              )?_c('div',{staticClass:"ma-3"},[_c('div',{staticClass:"my-2"},[_c('v-icon',{on:{"click":function($event){return _vm.billDetail(item.id)}}},[_vm._v(" mdi-eye ")])],1),_c('div',{directives:[{name:"can",rawName:"v-can:update",value:([_vm.$constants.PRIVILEGES.PAYMENT]),expression:"[$constants.PRIVILEGES.PAYMENT]",arg:"update"}]},[(_vm.billHavePaymentsForValidation(item))?_c('div',{staticClass:"my-2"},[_c('v-icon',{on:{"click":function($event){return _vm.paymentValidation(item.id)}}},[_vm._v(" mdi-cash-check ")])],1):_vm._e()])]):_vm._e()]}}],null,true)})],1):_vm._e()],1)],1),(!_vm.loader)?_c('v-row',{class:_vm.bills.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"pa-3 custom-card",attrs:{"elevation":"8"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.s-7-1"))+":")]),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"my-3"},[_c('v-chip',{attrs:{"small":"","label":"","color":"green","dark":""}},[_vm._v(_vm._s(_vm._f("status")("IN PROCESS")))]),_vm._v(" : "+_vm._s(_vm.$tc("Views.r-7"))+" ")],1),_c('div',{staticClass:"my-3"},[_c('v-chip',{attrs:{"small":"","label":"","color":"indigo","dark":""}},[_vm._v(_vm._s(_vm._f("status")("FINISHED")))]),_vm._v(" : "+_vm._s(_vm.$tc("Views.r-10"))+" ")],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }