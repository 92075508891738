
















































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import moment from "moment";
import { StyleCheck } from "@/mixins/style-check";
import FilePicker from "@/components/Input/FilePicker.vue";

@Component({
  components: { PageTitle, Loader, Empty, FilePicker },
})
export default class Returns extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  search = "";
  dialog = false;
  loader = false;
  loading = false;
  loadingDT = false;
  selectedSubscriptors = [];
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getBills(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage
    );
    this.loadingDT = false;
  }

  private async getBills(
    page: number,
    size: number,
    bill_id = ""
  ): Promise<void> {
    await this.$store
      .dispatch("bill/getBills", { page, size, bill_id, operation: "plus" })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.r-e11"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getBills(this.pagination.page, this.pagination.itemsPerPage);
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Views.r-h1"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.r-h2"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.r-h3"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.r-h4"),
        value: "progress",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.r-h5"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get bills(): any[] {
    let response = this.$store.getters["bill/getBills"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.bills) {
      return response?.bills;
    } else {
      return [];
    }
  }

  private billDetail(bill_id: string) {
    this.navigate("/staff/returns/detail/" + bill_id);
  }

  private paymentValidation(bill_id: string) {
    this.navigate("/staff/investments/payment-validation/" + bill_id);
  }

  private billHavePaymentsForValidation(bill: any): boolean {
    let response = false;

    bill.payments_cryptocurrency.forEach((payment) => {
      if (payment.status.name == this.$constants.STATUS.VALIDATING) {
        response = true;
      }
    });

    bill.payments_traditional.forEach((payment) => {
      if (payment.status.name == this.$constants.STATUS.VALIDATING) {
        response = true;
      }
    });

    return response;
  }

  private getInvestmentTimeline(bill: any) {
    const investment_start_date = moment(bill.date);
    const investment_end_date = moment(bill.end_date);
    const investment_current_date = moment();

    const investment_amount = bill.amount;

    const total_investment_time = investment_end_date.diff(
      investment_start_date
    );
    const current_investment_time = investment_current_date.diff(
      investment_start_date
    );
    let investment_current_percentage = Math.trunc(
      (100 * current_investment_time) / total_investment_time
    );

    if (investment_current_percentage < 0) {
      investment_current_percentage = 0;
    }

    if (investment_current_percentage > 100) {
      investment_current_percentage = 100;
    }

    return {
      investment_amount: investment_amount,
      investment_start_date: investment_start_date,
      investment_end_date: investment_end_date,
      investment_current_date: investment_current_date,
      total_investment_time: total_investment_time,
      current_investment_time: current_investment_time,
      investment_current_percentage: investment_current_percentage,
    };
  }

  //Search by ID
  private async searchReturnByID(clear: boolean) {
    this.loadingDT = true;
    if (this.search == null || clear === true) {
      this.search = "";
    }

    await this.getBills(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }
}
